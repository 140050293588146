import { CpfCnpjPipe } from './../pipes/cpfCnpj.pipe';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TablePipe } from './../pipes/table.pipe';
import { InputTextModule } from 'primeng/inputtext';
import { PRIMENG_IMPORTS } from './../primeng-imports';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { JhiDateUtils } from './date-util.service';
import { ScaDatatableComponent } from './sca-datatable/sca-datatable.component';
import { ScaDatatableGestaoUnidadesComponent } from "./sca-datatable-gestao-unidades/sca-datatable-gestao-unidades.component";
import { DropdownModule, CalendarModule } from 'primeng/primeng';
import { ScaBotoesExportacaoComponent } from './sca-botoes-exportacao/sca-botoes-exportacao.component';
import { StatusPipe } from '../pipes/status.pipe';
import { SecurityModule } from '@nuvem/angular-base';
import { SimNaoPipe } from '../pipes/simNao.pipe';
/* jhipster-needle-add-shared-service-import - JHipster will add shared services imports here */

@NgModule({
  declarations: [ScaDatatableComponent, ScaDatatableGestaoUnidadesComponent, TablePipe, StatusPipe, ScaBotoesExportacaoComponent, CpfCnpjPipe, SimNaoPipe],
  imports: [
    TableModule,
    CommonModule,
    ButtonModule,
    InputTextModule,
    DropdownModule,
    SplitButtonModule,
    SecurityModule.forRoot(),
    CalendarModule
  ],
  exports: [
    ScaDatatableComponent,
    ScaDatatableGestaoUnidadesComponent,
    ScaBotoesExportacaoComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [SimNaoPipe]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        JhiDateUtils,
        /* jhipster-needle-add-shared-services - JHipster will add shared services here */
      ]
    };
  }
}
